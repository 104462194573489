import { makeStyles } from '@material-ui/core/styles';

const widgetWidth = 400

export default makeStyles(theme => ({
  chatAreaBackground: {
    [theme.breakpoints.up('md')]: {
      padding: ({WIDE_MODE}) => WIDE_MODE ? '20px 0px 20px 20px' : 'auto',
      backgroundColor: ({WIDE_MODE, BACKGROUND_COLOR}) => WIDE_MODE ? BACKGROUND_COLOR : 'white',
    },
    '& span:empty, p:empty, h1:empty, h2:empty, h3:empty, h4:empty, h5:empty, h6:empty, div:empty, ul:empty, ol:empty, li:empty, main:empty, article:empty, aside:empty, details:empty, figcaption:empty, figure:empty, footer:empty, header:empty, menu:empty, nav:empty, section:empty, summary:empty': {
      display: 'revert',
    },
    '& *, *:before, *:after': {
      boxSizing: 'revert'
    },
    // 'p, h1, h2, h3, h4, h5, h6, div, span, ul, ol, li, main, article, aside, details, figcaption, figure, footer, header, menu, nav, section, summary': {
    //   margin: 'revert',
    //   padding: 'revert',
    // },
    '& h1, h2, h3, h4, h5, h6': {
      color: 'revert',
      fontWeight: 'revert',
    },
    '& button, .button': {
      // width: auto;
      // position: relative;
      // box-sizing: border-box;
      // border-radius: 2px;
      // border-width: 1px;
      // border-style: solid;
      transition: 'revert',
      // cursor: pointer;
      textDecoration: 'revert',
      textAlign: 'revert',
      fontWeight: 'revert',
      whiteSpace: 'revert',
      height: 'revert',
      // padding: 0 0.625rem;
    },
    '& button:hover, button:focus, .button:hover, .button:focus': {
      opacity: 'revert',
      // color: 'revert',
      // backgroundColor: '#828282',
    },
  },
  chatArea: {
    position: ({WIDE_MODE}) => WIDE_MODE ? 'auto' : 'fixed',
    bottom: ({WIDE_MODE}) => WIDE_MODE ? 'auto' : 15,
    height: 'auto',
    right: ({WIDE_MODE, launchButtonSize }) => WIDE_MODE ? 'auto' : launchButtonSize + 25,
    width: ({WIDE_MODE}) => WIDE_MODE ? '100%' : widgetWidth,
    transition: '.4s transform, .3s opacity',
    backgroundColor: 'white',
    boxShadow: '0px 0px 12px 0px rgba(0,0,0,0.29)',
    borderRadius: ({WIDE_MODE}) => WIDE_MODE ? '28px 0 0 28px' : 10,
    transform: 'scale(0)',
    opacity: 0,
    transformOrigin: 'bottom right',
    overflow: 'hidden',
    zIndex: 2000,
    '&.display': {
      transform: 'scale(1)',
      opacity: 1
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw) !important',
      right: '0px !important',
      bottom: '0px !important',
      borderRadius: '10px !important'
    }
  },
  copyrightLabel: {
    color: 'white',
    fontSize: 11,
    position: 'absolute',
    right: 10,
    bottom: 2,
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  '@keyframes flickerAnimation': {
    '0%': {
      opacity: 1
    },
    '50%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }
}));

import React from 'react';
import {
  Grid,
  Avatar
} from '@material-ui/core';
import {useConfigContext} from '../../../_context/config.context'
import useStyles, { Badge } from '../styles/headerStyles'
import {Close as CloseIcon} from "@material-ui/icons";

const botImgSrc = `${process.env.PUBLIC_URL}/header_icon_fit.png`;
const logoImgSrc = `${process.env.PUBLIC_URL}/msig-logo.png`;
const brandCurveImgSrc = `${process.env.PUBLIC_URL}/msigbrandcurve.png`;

/**
 * Use `Header` to provide a source of info for the bot.
 */

export default function Header({ handleEndChatBtn }) {
  const {config} = useConfigContext()
  const classes = useStyles(config);
  const { ENABLE_FEEDBACK } = config

  const endChat = () => {
    setTimeout(()=>{
      handleEndChatBtn(ENABLE_FEEDBACK)
    }, 100)
  }

  return (
    <Grid item xs={12} className={classes.header} data-testid='header-container'>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        <Avatar alt="Stock avatar" src={botImgSrc} className={classes.avatar} />
      </Badge>
      <h1 className={classes.headerText} data-testid='header-bot-name'>
        {config.BOT_NAME}
      </h1>
      <CloseIcon className={classes.ellipsisIcon} onClick={endChat} data-testid='header-menu-btn'/>
      <div className={classes.brandBlock}>
        <img src={brandCurveImgSrc} alt="" className={classes.brandCurve} />
        <div className={classes.logoWrapper}>
          <img src={logoImgSrc} alt="" className={classes.logo} />
        </div>
      </div>
    </Grid>
  );
}

const settings = {
  BOT_TEXT_MAX_LINES: 16,
  maintenance: {
    active: false,
    title: "We're undergoing scheduled maintenance",
    message: 'Please check back soon, just putting the finishing touches on some pretty awesome updates!'
  },
  GA: [
    {
      type: 'event',
      name: 'testing event',
      payload: {
        event_category: 'sample',
        event_label: 'SAMPLE LABEL',
        value: 12
      } //payload depends on type of event being sent.
    }
  ],
  AUTO_RESTART_SESSION: false,
  LOGIN_REQUIRED: false,
  ENABLE_FEEDBACK: false,
  HIDE_RESTART_ALERT_ON_FEEDBACK_PAGE: true,
  FEEDBACK_FORM_NAME: '',
  IMG_TEMPLATE_SHOW_FULL: false,
  SHOW_LAST_MESSAGE_ON_INPUT_FOCUS: true,
  PERSISTENT_MENU_BUTTON: true,
  SHOW_USER_FORM_RESPONSE: false,
  SHOW_WATERMARK: false,
  SOCKET_DIALOG: {
    title: 'Session has been disconnected.',
    subTitle: 'Click the button below or refresh the page to restart the session',
    buttonText: 'Restart'
  },
  WELCOME_SCREEN_SETTINGS: {
    display: false,
    welcomeTitle: 'Hello!',
    welcomeMessageMain: 'I am MAE and I make retrieving information easy.',
    welcomeMessageSecondary: 'Ask me about anything.',
    actionButton: {
      active: false,
      text: 'Button Label',
      secondaryText: '',
      payload: 'FL5be7a85b4e2da6b76d7f3b64_0'
    }
  },
  WIDE_MODE: false,
  CHAT_OPEN_BY_DEFAULT: false,
  USE_SESSION: false,
  USE_CUSTOM_LAUNCHER_ICON: true,
  LAUNCHER_BUTTON_ID: 'launcherButton',
  QR_LIMIT: 4,
  QR_SHOW_ALL: false,
  WEBSHARE_IGNORED_QRS: ['5ece45e8b3c31a04fdf4d36e'], //default webshare payload, to hide when webshare is unavailable
  USE_DYNAMIC_QR_LENGTH: true,
  ENABLE_FILE_UPLOAD: false,
  HEADER_MENU: true,
  HEADER_MENU_ITEMS: [
    { label: "End Chat", icon: "fas fa-power-off", payload: "end" },
  ],
  NAVIGATION_ITEMS: [
    { label: "*Travel*_Easy_^®^", icon: "fas fa-plane", payload: "FL60111da4fe87e14f2c05cad8_0" },
    { label: "*MotorMax*", icon: "fas fa-car", payload: "FL600910e4b6ae7ce9cca2e548_0" },
    { label: "*Maid*_Plus_", icon: "fas fa-female", payload: "FL5f3a744f8bafb9077cbc2715_0" },
    { label: "*Enhanced Home*_Plus_^®^", icon: "fas fa-home", payload: "FL5ffcf2b603850b26e9549c03_0" },
    { label: "*CancerCare*_Plus_", icon: "fas fa-first-aid", payload: "FL5ff66c2cc3173f529ac3628d_0" },
    { label: "*Global Study*", icon: "fas fa-globe", payload: "FL6017d86b70a96aa9bd6ffc44_0" },
    { label: "*Menu*", icon: "fas fa-bars", payload: "FL5f2151914f5568c783c1f798_0" },
  ],
  //****************  Colours and labels *********************** //
  /*
    Available installed fonts:
    - 'Helvetica Neue'
    - 'Raleway, sans-serif'
    - 'Noto Sans, sans-serif'
  */
  BUTTON: {  //non hover state colors follow global color, this is to prevent color clashing
    hoverState: {
      color: 'white',
      borderColor: '#1b166e',
      backgroundColor: '#1b166e'
    }
  },
  FONT_FAMILY: "'Ubuntu-Regular', Ubuntu local, Helvetica, sans-serif",
  WELCOME_COLOR: "#e60000",
  HEADER_COLOR: "#7a7497",
  BK_HEADER_COLOR: "#1b166e",
  GLOBAL_COLOR: "#1b166e",
  BK_GLOBAL_COLOR: "#b0acc4",
  USER_MESSAGE_COLOR: "white",
  SEND_ICON_COLOR: "#1b166e",
  BOT_MSG: { //change values to string if u want to change the colors, otherwise leave as null
    color: null,
    backgroundColor: "#e3e2ee",
    timeStampColor: null
  },
  SIDEBAR: {
    enableEndChatButton: false,
    color: "#000000",
    borderColor: '#000000',
    backgroundColor: "transparent",
    logo: {
      width: 86,  // leave as auto unless width is broken. Either a number, 'auto' or '90%' (percentage based value)
      height: 106, // leave as auto unless height is broken. Either a number, 'auto' or '90%' (percentage based value)
      url: 'https://www.msig.com.sg'
    },
  },
  BACKGROUND_COLOR: "#cdd9e0",
  PERSISTENT_MENU: {
    color: '',
    backgroundColor: '#e60000'
  },
  NOTIFICATION_BANNER: {
    color: "#000000",
    backgroundColor: '#fed94bf0',
    button: {
      color: 'white',
      backgroundColor: '#0b5fa8'
    }
  },
  SHARE_BUTTON_BACKGROUND_COLOR: "#2d55b2cf",
  SHARE_ICON_COLOR: "white",
  BOT_NAME: "MSIG MAE"
}

export default settings

//get icons from https://fontawesome.com/icons?d=gallery&m=free <- only use free icons, if not just ask me, ill update the icons.

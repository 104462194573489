import React, {useRef, useState, useEffect} from 'react';
import clsx from 'clsx'
import { Backdrop, Slide } from '@material-ui/core';
import {useConfigContext} from '../../_context/config.context'
import useStyles from './styles'
import formatter from "../../_helpers/formatter";

export default function PersistentMenu({ toggleMenu, menuOpen, sendMessage }) {
  const {config} = useConfigContext()
  const scrollRef = useRef(null)
  const [hideArrow, setHideArrow] = useState({ left: true, right: false })
  const {NAVIGATION_ITEMS} = config

  const isIE11 = document.documentMode || /Edge/.test(navigator.userAgent)
  const classes = useStyles({ isIE11, ...config })

  const triggerPayload = (e, item) => {
    sendMessage({
      title: item.label,
      payload: item.payload,
      text: item.label
    })
    toggleMenu(e);
  }

  const scroll = direction => {
    const newScrollPos = direction === 'left' ? scrollRef.current.scrollLeft - 200 : scrollRef.current.scrollLeft + 200
    isIE11 ? scrollRef.current.scrollLeft = newScrollPos
    :
    scrollRef.current.scrollTo({left: newScrollPos, behavior: 'smooth'})
  }

  const handleScroll = () => {
    const currentScrollPos = scrollRef?.current?.scrollLeft
    if(scrollRef?.current?.scrollWidth - scrollRef?.current?.clientWidth === 0) { //hide both arrows if there are not enough options to scroll
      setHideArrow({ left: true, right: true})
    } else if(currentScrollPos <= 0) { // show left arrow
      setHideArrow({ left: true, right: false})
    } else if(currentScrollPos >= scrollRef?.current?.scrollWidth - scrollRef?.current?.clientWidth) { //show right arrow
      setHideArrow({ left: false, right: true})
    } else if(currentScrollPos > 0 && currentScrollPos < scrollRef?.current?.scrollWidth - scrollRef?.current?.clientWidth) { //show both arrows
      setHideArrow({ left: false, right: false})
    }
  }

  useEffect(() => {
    handleScroll()
  }, [menuOpen])

  return (
    <Backdrop open={menuOpen} onClick={toggleMenu} classes={{ root: classes.backdrop}} id='persistent-menu-backdrop' data-testid='persistent-menu-backdrop'>
      <label className={classes.clickToCloseLabel}>Click outside to close</label>
      <Slide direction="up" in={menuOpen} mountOnEnter unmountOnExit onClick={e => e.stopPropagation()}>
        <div className={classes.backdropContent} data-testid='persistent-menu-container'>
          <div className='menuHeader'>
            Need some help?
          </div>
          <div className={classes.container}>
            <div
              onClick={() => scroll('left')}
              className={clsx('leftArrow', "persistentMenuArrow", hideArrow.left && 'hide')}
              data-testid='persistent-menu-left-arrow'
            >
              <i className="fas fa-angle-left" />
            </div>
            <div className={classes.scrollContainer} ref={scrollRef} onScroll={handleScroll} data-testid='persistent-menu-item-container'>
              {
                NAVIGATION_ITEMS.map((item, i) =>
                  <div
                    id='menu-backdrop-button'
                    data-testid='persistent-menu-item'
                    className={clsx(classes.scrollItem, item.label, !item.icon && 'noIcon')}
                    key={i}
                    onClick={(e) => triggerPayload(e, item)}
                  >
                    <div>
                      {item.icon && <i className={item.icon} id='menu-backdrop-icon' />}
                      {formatter(item.label)}
                    </div>
                  </div>
                )
              }
            </div>
            <div
              onClick={() => scroll('right')}
              className={clsx("rightArrow", "persistentMenuArrow", hideArrow.right && 'hide')}
              data-testid='persistent-menu-right-arrow'
            >
               <i className="fas fa-angle-right" />
             </div>
          </div>
        </div>
      </Slide>
    </Backdrop>
  );
}

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField as MaterialTextField } from '@material-ui/core';

export const TextField = withStyles({
  root: {

  },
})(MaterialTextField);

export default makeStyles(theme => ({
  inputContainer: {
    position: 'relative',
    padding: 8,
    paddingLeft: ({WIDE_MODE}) => WIDE_MODE ? 20 : 8,
    display: 'flex',
    flexDirection: 'column',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.09)',
    minHeight: 55, //only here because of IE11
    boxSizing: 'border-box !important',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  },
  userInput: {
    fontFamily: 'inherit',
    padding: 0,
    margin: 0,
    width: '100%',
    '&.fileUploaded': {
      '& .MuiOutlinedInput-root': {
        fontFamily: 'inherit',
        '& textarea': {
          fontFamily: 'inherit',
          "&::placeholder": {
            color: 'red',
            opacity: 1
          }
        },
      }
    },
    '&.MuiOutlinedInput-multiline': {
      '& .MuiOutlinedInput-marginDense': {
        padding: 0
      }
    },
    '& .MuiOutlinedInput-root': {
      fontFamily: 'inherit',
      '& textarea': {
        minHeight: '16px !important',
        '&:focus': {
          border: 0,
        },
        "&::placeholder": {
          color: 'black',
          opacity: 1
        }
      },
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  },
  sendIcon: {
    fill: ({ SEND_ICON_COLOR }) => SEND_ICON_COLOR,
    padding: 5,
    margin: '6px 12px 0px 12px',
    fontSize: 20,
    transition: '0.3s transform, 0.4s opacity',
    cursor: 'pointer',
    opacity: 1,
    '&:active': {
      transform: 'scale(1.4) rotate(15deg)'
    },
    '&.hideSendIcon': {
      opacity: 0,
      transform: 'translateX(10px)'
    }
  },
  menuIcon: {
    width: 24,
    height: 24,
    fill: ({ SEND_ICON_COLOR }) => SEND_ICON_COLOR,
    padding: '8px 15px',
    borderRadius: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e6e6e6'
    },
    [theme.breakpoints.up('md')]: {
      display: ({WIDE_MODE}) => WIDE_MODE ? 'none' : 'auto'
    }
  },
  fileUploadBtn: {
    padding: '9px 4px',
    '& svg': {
       fill: ({ SEND_ICON_COLOR }) => SEND_ICON_COLOR,
       width: 24,
       height: 24
    }
  },
  fileLabelDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 42,
    width: '100%',
    '& h4': {
      fontWeight: 300,
      margin: '0 10px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 155
      }
    },
    '& img': {
      height: 30
    },
    [theme.breakpoints.down('sm')]: {
      '& .preText': {
        display: 'none'
      }
    }
  },
  watermark: {
    textAlign: 'center',
    color: '#8c8c8c',
    padding: '10px 0 10px 0',
    fontSize: 11,
    display: ({WIDE_MODE}) => WIDE_MODE ? 'none' : 'initial',
    [theme.breakpoints.only('xs')]: {
      display: 'initial !important'
    }
  }
}));

import { makeStyles } from '@material-ui/core/styles';

const backdropIndex = 2600

export default makeStyles(theme => ({
  backdrop: {
    zIndex: backdropIndex,
    borderRadius: ({WIDE_MODE}) => WIDE_MODE ? '28px 0 0 28px' : 8,
  },
  backdropContent: {
    maxHeight: ({ height, WIDE_MODE }) => WIDE_MODE ? `${ height * 0.85 - 100}px` : `${ height * 0.65 - 100}px`,
    overflow: 'scroll',
    textAlign: 'center',
    boxSizing: 'border-box',
    width: '360px',
    backgroundColor: 'white',
    borderRadius: 8,
    padding: '2px 20px 10px 20px',
    zIndex: backdropIndex + 1,
    '& .menuHeader': {
      padding: '15px 20px 12px 20px',
      fontWeight: 'bold'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  header: {
    fontSize: 18,
    fontWeight: 700,
    margin: '20px 10px',
  },
  option: {
    margin: 10,
    borderRadius: 10,
    cursor: 'pointer',
    padding: 10,
    border: ({GLOBAL_COLOR}) => `1px solid ${GLOBAL_COLOR}`,
    color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    fontSize: 14,
    fontWeight: 'bold',
    // boxShadow: '0px 3px 5px 1px #c5c5c5',
    '&:active': {
      transform: 'translateY(4px)'
    },
    '&:hover': {
      // filter: 'brightness(120%)'
    }
  }
}));

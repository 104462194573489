import React, {useState, useRef, useEffect} from 'react';
import clsx from 'clsx'
import { Grid, Button, Tooltip } from '@material-ui/core';
import FileIconGenerator from '../../../_helpers/fileIconGenerator'
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/telegram.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-box.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/file-upload.svg';
import useStyles, { TextField } from '../styles/userInputStyles'
import {useConfigContext} from '../../../_context/config.context'

export default function UserInput({
  toggleMenu,
  sendMessage,
  inputDisabled,
  triggerInputFocusScroll,
  sendFile,
  resetUserMsg
}) {
  const {config} = useConfigContext()
  const sendBtnRef = useRef(null)
  const fileUploadInputRef = useRef(null)
  const [files, setFiles] = useState([])
  const [value, setValue] = useState('')
  const {PERSISTENT_MENU_BUTTON, ENABLE_FILE_UPLOAD, SHOW_WATERMARK} = config
  const classes = useStyles(config);
  const handleFiles = e => {
    setFiles(e.target.files)
    sendBtnRef.current.focus()
  }

  const resetInput = () => {
    setFiles([])
    fileUploadInputRef.current.value = ''
  }

  const handleSendBtn = e => {
    if(e?.key === 'Enter' && files.length > 0) {
      sendFile(files)
      resetInput()
      return
    }

    if(files.length > 0) {
      sendFile(files)
      resetInput()
    } else {
      sendMessage(null, value)
    }
  }

  const handleChange = e => {
    setValue(e.target.value)
  }

  const checkForEnterKey = e => {
    /* Check for shift + enter combination, block default form submit request and create newline if true */
    if(e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      sendMessage(null, value)
    }
  }

  useEffect(() => {
    setValue('')
  }, [resetUserMsg])
  
  return (
    <Grid item xs={12} className={classes.inputContainer}>
      <div style={{ display: 'flex'}}>
        {
          !inputDisabled
          &&
          PERSISTENT_MENU_BUTTON
          &&
          <MenuIcon
            className={classes.menuIcon}
            onClick={toggleMenu}
            style={{ color: '#1b1464' }}
            id='persistent-menu-backdrop'
            data-testid='userInput-menu-btn'
          />
        }
        <div>
          <input
            accept="image/*, video/*, .pdf, .doc, .docx, .xls, .xslx, .pptx, .ppt" // video/* or image/* or image/*,.pdf
            className={classes.input}
            id="file-upload-input"
            type="file"
            ref={fileUploadInputRef}
            style={{ display: 'none'}}
            disabled={inputDisabled}
            onChange={handleFiles}
          />
          {
            !ENABLE_FILE_UPLOAD ?
            null
            :
            files.length > 0
            ?
            <Tooltip title="Remove Files">
              <Button component="span" className={classes.fileUploadBtn} onClick={resetInput} disabled={inputDisabled}>
                <CloseIcon />
              </Button>
            </Tooltip>
            :
            <label htmlFor="file-upload-input">
              <Tooltip title='Upload File'>
                <Button component="span" className={classes.fileUploadBtn} disabled={inputDisabled} data-testid='userInput-fileUpload-btn'>
                  <UploadIcon />
                </Button>
              </Tooltip>
            </label>
          }
        </div>
        {
          files.length > 0
          ?
          <div className={classes.fileLabelDiv}>
            <h4 className='preText'>Press enter or click on send icon to upload</h4>
            <img src={FileIconGenerator(files[0].type.split('/')[1])} alt='fileIcon' />
            <h4>{files[0].name.split('.').shift()}</h4>
          </div>
          :
          <TextField
            InputProps={{
              onFocus: triggerInputFocusScroll
            }}
            name='userMessage'
            placeholder={
              !inputDisabled
              ?
              'Type your message'
              :
              'Disconnected from server'
            }
            inputProps={{ 
              'aria-label': 'user-message-input',
              "data-testid": 'userInput-textField',
            }}
            variant='outlined'
            margin='dense'
            className={clsx(classes.userInput, files.length > 0 && 'fileUploaded')}
            onChange={handleChange}
            onKeyPress={checkForEnterKey}
            value={value}
            disabled={inputDisabled || files.length > 0}
            rowsMax={4}
            multiline
          />
        }
        <SendIcon
          data-testid='userInput-send-btn'
          ref={sendBtnRef}
          tabIndex='0'
          onKeyPress={handleSendBtn}
          className={clsx(
            classes.sendIcon,
            value === '' && files.length === 0 && 'hideSendIcon'
          )}
          onClick={handleSendBtn}
        />
      </div>
      {
        SHOW_WATERMARK
        &&
        <div className={classes.watermark} data-testid='userInput-watermark'>
          POWERED BY PAND.AI
        </div>
      }
    </Grid>
  );
}
